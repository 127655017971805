
import './App.css';
import { BrowserRouter, Route, Routes, Link, Navigate, useMatch, useLocation } from 'react-router-dom';
import { useState, useEffect, lazy, Suspense } from 'react';
import '@aws-amplify/ui-react/styles.css';
import './custom.css';
import { Auth } from 'aws-amplify';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import {StreamerVote} from "./Home/StreamerVote.js"
import {PurchasePage} from "./Home/PurchasePage.js"
import {PresalePage} from "./Home/PresalePage.js"
import awsconfig from './aws-exports';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import {TurretFiredImageView} from "./Home/TurretFiredImageView.js"
import {TreatBotAppAuthView} from "./Home/TreatBotAppAuthView.js"
import {TreatbotProgressView} from "./Home/TreatbotProgressView.js"


import AddressFormPage from './Home/AddressFormPage.js';

awsconfig["API"] = {
  endpoints: [
      {
        name: "rover",
        endpoint: "https://slqdlhy2he.execute-api.us-east-2.amazonaws.com/develop",
        custom_header: async () => {
          return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: "rover",
        endpoint: "https://afvzs83quk.execute-api.us-east-2.amazonaws.com/prod",
        custom_header: async () => {
          return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }

Amplify.configure(awsconfig);
Amplify.addPluggable(new AWSIoTProvider({
   aws_pubsub_region: 'us-east-2',
   aws_pubsub_endpoint: 'wss://a14f86uvpchkbq-ats.iot.us-east-2.amazonaws.com/mqtt',
 }));





function AppRouter(props) {
  const location = useLocation();

  const [isDisplayMessage, setIsDisplayMessage] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const pathArray = location.pathname.split("/")
    console.log(pathArray)
    setIsDisplayMessage(pathArray[1] == "overlay")
    if (pathArray[1] == "rover") {
      var roverIdString = pathArray[2];
    }

  },[location])

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((signedInUser) => {
      const userId = signedInUser.username
      setLoggedIn(true)
      console.log("loggedIn")
    }).catch(err => {
      setLoggedIn(false)
    })

  },[location])



  // const url = "https://localhost:3000/"
  const url = "https://www.treatbot.gg/"





  return (
    <div className="App">
   {!isDisplayMessage && <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">

        <a className="nav-link dark-link site-title ps-5 pe-auto me-auto" href="/">Treat Bot</a>
        {/* <a className="nav-link purple-link me-auto" href="/vote">(Free Treat Bot)</a> */}
        {/* <a className="d-none d-sm-none d-md-block" href="https://discord.gg/wa7eJP74UJ" target="_blank"><img src="./streamturret-icons/icons8-discord-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.twitch.tv/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.instagram.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-instagram-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://twitter.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitter-100.png" width="35"></img></a>
        <a className="ms-2 me-5 d-none d-sm-none d-md-block" href="mailto:support@treatbot.gg" target="_blank"><img src="./streamturret-icons/icons8-email-100.png" width="35"></img></a>
         */}
        <a className="" href="https://twitter.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitter-100.png" width="35"></img></a>
        <a className="ms-2" href="https://www.instagram.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-instagram-100.png" width="35"></img></a>
        <a className="ms-2" href="https://www.twitch.tv/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="35"></img></a>
        <a className="ms-2" href="https://discord.gg/wa7eJP74UJ" target="_blank"><img src="./streamturret-icons/icons8-discord-100.png" width="35"></img></a>
        <a className="ms-2 me-5" href="mailto:support@treatbot.gg" target="_blank"><img src="./streamturret-icons/icons8-email-100.png" width="35"></img></a>
      </div>
    </nav>}
    <div className="container-fluid min-vh-100">

    <Suspense fallback={<div><p>Loading...</p></div>}>
    <Routes>
      {/* <Route exact path='/' element={<JoinPage url={url} />}/> */}
      <Route exact path='/' element={<PresalePage url={url} />}/>
      {/* <Route exact path='/vote' element={<StreamerVote url={url} />}/> */}
      <Route exact path='/overlay/displayMessage/:twitchUserId' element={<TurretFiredImageView/>}/>
      <Route exact path='/overlay/treatProgress/:roverId' element={<TreatbotProgressView progressType={"treatProgress"}/>}/>
      <Route exact path='/overlay/treatBoss/:roverId' element={<TreatbotProgressView progressType={"treatBoss"}/>}/>
      <Route exact path='/overlay/treatBossAndProgress/:roverId' element={<TreatbotProgressView progressType={"treatBossAndProgress"}/>}/>
      {/* <Route exact path='/store' element={<PurchasePage url={url} />}/> */}
      {/* <Route exact path='/manage' element={<TurretConfigView />}/> */}
      <Route exact path='/treatbotappauth' element={<TreatBotAppAuthView />}/>
      {/* <Route exact path='/winner' element={<AddressFormPage url={url}/>}/> */}
      <Route exact path='/privacy' element=<PDFIframe src='https://streamturretimg.s3.us-east-2.amazonaws.com/Treat+Bot+Privacy+Policy+2023-09-28.pdf' /> />
      <Route exact path='/terms' element=<PDFIframe src='https://streamturretimg.s3.us-east-2.amazonaws.com/Treat+Bot+Terms+of+Service+2023-09-28.pdf' /> />
    </Routes>
    </Suspense>

    {!isDisplayMessage && <div className="col-12">
      <div className="row d-block d-sm-block d-md-none my-5">
        <a className="d-none d-sm-none d-md-block" href="https://discord.gg/wa7eJP74UJ" target="_blank"><img src="./streamturret-icons/icons8-discord-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.twitch.tv/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.instagram.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-instagram-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://twitter.com/treatbotgg" target="_blank"><img src="./streamturret-icons/icons8-twitter-100.png" width="35"></img></a>
        <a className="ms-2 d-none d-sm-none d-md-block" href="https://www.linkedin.com/company/type-2-labs/" target="_blank"><img src="./streamturret-icons/icons8-linkedin-100.png" width="35"></img></a>
        <a className="ms-2 me-5 d-none d-sm-none d-md-block" href="mailto:support@treatbot.gg" target="_blank"><img src="./streamturret-icons/icons8-email-100.png" width="35"></img></a>
      </div>
      <div className="row mb-5 justify-content-left mx-5">
        <div className="col-auto"><Link to="/privacy" className="navbar-brand" href="#">privacy</Link></div>
        <div className="col-auto"><Link to="/terms" className="navbar-brand" href="#">terms of service</Link></div>
      </div>
    </div>}

    </div>

    </div>
  )

}

function PDFIframe(props) {
  return (

    <div class="row vh-100">
      <div class="col">
        <iframe width="100%" height="100%"  src={props.src +"#zoom=FitW"}></iframe>

      </div>
    </div>
    

  )
}


function TurretConfigView() {
  return (
      <div className="row justify-content-center">
        <div className="col-auto">
        <div className="row store-title mb-4">
          Manage Live Stream Turret
        </div>
          <div className="row">
            <a href="https://dashboard.twitch.tv/popout/u/streamturret/extension-live-configure/7kt3bdtstu0glmuxm9506zr8h0hk8z:0.0.2">
              <button className="btn btn-purple">
                Stream Turret Live Config
             </button>
            </a>
            </div>
        </div>
      </div>
)
}




function App(props) {



  return (
    <BrowserRouter>
    <AppRouter/>
    </BrowserRouter>

  );
}

export default App;
