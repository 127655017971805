import { useState, useEffect } from 'react';

export function ProductImageArea(props) {

  const [imageIndex,setImageIndex] = useState(0);

  const images = [
    "https://streamturretimg.s3.us-east-2.amazonaws.com/treatbot_store_1.jpg",
    "https://streamturretimg.s3.us-east-2.amazonaws.com/treatbot_store_2.jpg",
    "https://streamturretimg.s3.us-east-2.amazonaws.com/treatbot_store_3.jpg"
    
  ]

  function previousImage() {
    // const idx = Math.max(0, imageIndex - 1)
    // const idx = Math.min(images.length - 1, imageIndex + 1)
    var idx = imageIndex - 1
    if (idx < 0) {
      idx = images.length - 1
    }
    setImageIndex(idx)
  }

  function nextImage() {
    // const idx = Math.min(images.length - 1, imageIndex + 1)
    // const idx = Math.max(0, imageIndex - 1)
    var idx = imageIndex + 1
    if (idx >  (images.length - 1)) {
      idx = 0
    }
    console.log(idx)
    setImageIndex(idx)
  }

  return (

    <div className="row px-4 justify-content-center py-3">
      <div class="col-12">

      	<img src={images[imageIndex]} width="80%"/></div>

      	<div className="row my-1">
      		<div className="col-6">
      			<button className="btn btn-light " onClick={previousImage}>
              <img src="./streamturret-icons/icons8-chevron-left-100.png" width="30"></img>
            </button>
      		</div>

      		<div className="col-6">
      			<button className="btn btn-light" onClick={nextImage}>
              <img src="./streamturret-icons/icons8-chevron-right-100.png" width="30"></img>
            </button>
      		</div>
      	</div>
            
          </div>

          

    )

}