import { Auth, API } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import {turretVoteGetVoterInfo, turretVoteFindStreamer, turretVoteVote, turretVoteJoinWaitlist, turretStoreGetDepositLink} from "../apiConn.js"
import { useState, useEffect } from 'react';
import { streamTurretVoteCountByContestIdAndTotalVotes, listStripeProductCounts} from '../graphql/queries';
import {ProductImageArea} from "./ProductImageArea.js" 

export function PresalePage(props) {

  const [didSearchStreamer, setDidSearchStreamer] = useState(true);
  const [searchWarning, setSearchWarning] = useState(null);
  const [voterInfo, setVoterInfo] = useState(null);
  const [didUseVote, setDidUseVote] = useState(true);
  const [nVotesAvailable, setNVotesAvailable] = useState(0)
  const [twitchAccessToken, setTwitchAccessToken] = useState(null);
  const [foundStreamer, setFoundStreamer] = useState(null);
  const [streamTurretVoteCounts, setStreamTurretVoteCounts] = useState(null);
  const [selectedBatchIndex, setSelectedBatchIndex] = useState(0);
  const [voteCountNextToken, setVoteCountNextToken] = useState([]);
  const [voteCountPreviousToken, setVoteCountPreviousToken] = useState([]);
  const [voteCountSelectedToken, setVoteCountSelectedToken] = useState(null);
  const [productList, setProductList] = useState([])
  const [didSetProductList, setDidSetProductList] = useState(false)
  const [awaitingJoinResponse, setAwaitingJoinResponse] = useState(false);
  const [didJoinProductWaitlist, setDidJoinProductWaitlist] = useState(false)
  const [selectedWaitlistBatch,setSelectedWaitlistBatch] = useState(true)
  const [selectedProductId,setSelectedProductId] = useState("")
  const [selectedPriceId,setSelectedPriceId] = useState("")
  const [selectedId,setSelectedId] = useState("")

  const location = useLocation();
  const locPath = location.hash.split("#").pop()
  const params = locPath.split("&")
  const paramDict = {}
  const searchParams = new URLSearchParams(location.search)
  var streamerSearch = searchParams.get("streamer")



  if (!streamerSearch) {
    streamerSearch = window.localStorage.getItem('lastFoundStreamer')
  }


  const navigate = useNavigate()


  useEffect(() => {

    

    if (paramDict.access_token) {
      setTwitchAccessToken(paramDict.access_token)
    } else {
      console.log("set token",window.localStorage.getItem('twitchAccessToken'))
      setTwitchAccessToken(window.localStorage.getItem('twitchAccessToken'));
    }

    if (streamerSearch) {
      document.getElementById("streamerSearch").value = streamerSearch
      findStreamer()
    }

    
  }, []);

  useEffect(() => {
    console.log("accesstoken",twitchAccessToken)
    if (!twitchAccessToken) {
      return
    }

    window.localStorage.setItem('twitchAccessToken', twitchAccessToken);

    if (twitchAccessToken) {
      turretVoteGetVoterInfo(twitchAccessToken)
        .then(res => {
          console.log('res',res)
          setVoterInfo(res)
        })
      }
  }, [twitchAccessToken]);

  

  useEffect(() => {

    if (awaitingJoinResponse == true) {
        return
    }

    turretVoteGetVoterInfo(twitchAccessToken)
    .then(res => {
        console.log('res',res)
        setVoterInfo(res)
        fetchStreamTurretVoteCounts()
        findStreamer()
        // setDidJoinProductWaitlist(res.didJoinWaitlist)
        
    })
  },[awaitingJoinResponse])

  useEffect(() => {
    fetchStreamTurretVoteCounts()
    fetchStripeProductCounts()
  }, [navigate]);

  useEffect(() => {
    fetchStreamTurretVoteCounts()
    fetchStripeProductCounts()
  }, [voteCountSelectedToken]);

  useEffect(() => {

    if (voterInfo) {
      setNVotesAvailable(voterInfo.votesAvailable)
      setDidJoinProductWaitlist(voterInfo.didJoinWaitlist)
    }
    
  }, [voterInfo]);




useEffect(() => {
    console.log("products list",productList)
    setDidSetProductList(true)
},[productList])

  params.forEach(param => {
    var p = param.split("=")
    paramDict[p[0]] = p[1]
  })

  console.log(paramDict)


  const [seeWinners, setSeeWinners] = useState(false)
  function didClickTopStreamers() {
    setSeeWinners(false)
  }
  function didClickWinners() {
    setSeeWinners(true)
  }

  
  function fetchStripeProductCounts() {
    return Auth.currentUserInfo().then(currentUserInfo => {
      var query = { query: listStripeProductCounts, variables: {sortDirection: "ASC", limit: 10}}
      if (!currentUserInfo) {
        query["authMode"] = 'AWS_IAM'
      }
      return API.graphql(query)
      .catch(err => {
        console.log(err)
        console.log(err.data.listStripeProductCounts)
        if (err.data.listStripeProductCounts.items.length > 0) {
          return Promise.resolve(err)
        } else {
          throw(err)
        }
      }).then(res => {
        const data = res.data.listStripeProductCounts.items
        console.log("products",data)
        data.sort((a, b) => a.sortOrder - b.sortOrder);
        setProductList(data)
        setDidSetProductList(true)
        setSelectedWaitlistBatch(data[0].isWaitlist)
        return
      })
    })

  }




  function fetchStreamTurretVoteCounts() {
    return Auth.currentUserInfo().then(currentUserInfo => {
      var query = { query: streamTurretVoteCountByContestIdAndTotalVotes, variables: {contestId: "8c15c6fd-a018-4133-b0f5-537345698bf2", sortDirection: "DESC", limit: 3}}
      if (voteCountSelectedToken != null) {
        query["variables"]["nextToken"] = voteCountSelectedToken
      }
      console.log(currentUserInfo)
      if (!currentUserInfo) {
        query["authMode"] = 'AWS_IAM'
      }
      return API.graphql(query)
      .catch(err => {
        console.log(err)
        console.log(err.data.streamTurretVoteCountByContestIdAndTotalVotes)
        if (err.data.streamTurretVoteCountByContestIdAndTotalVotes.items.length > 0) {
          return Promise.resolve(err)
        } else {
          throw(err)
        }
      }).then(res => {
        const data = res.data.streamTurretVoteCountByContestIdAndTotalVotes.items
        // console.log("streamTurretVoteCountByContestIdAndTotalVotes",res.data.streamTurretVoteCountByContestIdAndTotalVotes.nextToken,data)
        console.log("nextToken",res.data.streamTurretVoteCountByContestIdAndTotalVotes.nextToken)
        console.log("votesFound",data)
        setVoteCountNextToken(res.data.streamTurretVoteCountByContestIdAndTotalVotes.nextToken)
        setStreamTurretVoteCounts(data)
        return
      })
    })

  }
  console.log("voterInfo",voterInfo)


  function findStreamer() {
      const streamerName = document.getElementById("streamerSearch").value
      if (streamerName == "") {
        window.localStorage.setItem('lastFoundStreamer',"");
        setSearchWarning(null)
        setFoundStreamer(null)
        return
      }
      console.log(streamerName)
      turretVoteFindStreamer(streamerName)
      .then(res => {
        console.log(res)
        setFoundStreamer(res.voteCount)

        if (!res.authorized) {
          setSearchWarning(res.message)
        } else {
          setSearchWarning(null)
        }
        window.localStorage.setItem('lastFoundStreamer',streamerName);
        setDidSearchStreamer(!didSearchStreamer)
      })
  }


  function handleBatchClick(batchIndex){
    console.log(batchIndex)
    setSelectedBatchIndex(batchIndex)
    console.log("purchaseBot",productList[batchIndex])
    setSelectedId(productList[batchIndex].id)
    setSelectedProductId(productList[batchIndex].stripeProductId)
    setSelectedPriceId(productList[batchIndex].stripePriceId)
    setSelectedWaitlistBatch(productList[batchIndex].isWaitlist)
  }

  

  return (
    <div className="col-12 min-vh-100">

        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <ProductImageArea />
            </div>
        </div>

        <div className="row px-5 py-4 justify-content-center">
            
            <p className="store-title-bold">Treat Bot</p>
            <p className="store-title2-bold">Twitch connected treat dispenser</p>
            <p className="">Followers use Bits to feed your pet treats on streaming using our extension</p>

        </div>


        
        

      <div className="row justify-content-center">
           <div className="col-10 col-sm-7 col-md-6 col-lg-6 col-xl-4 col-xxl-4"> 

                {productList.map((product, index) => (
                    <div className="row py-2">
                        <div className="col" onClick={() => handleBatchClick(index)}>
                            <BatchCard stripeProduct={product} selectedBatchIndex={selectedBatchIndex} batchName="Batch Name"></BatchCard>
                        </div>
                    </div>
                ))}
            </div>
        </div>


      
    {(didJoinProductWaitlist || !didJoinProductWaitlist) && selectedWaitlistBatch &&
        <LogInToVoteButton buttonType="join" twitchAccessToken={twitchAccessToken} voterInfo={voterInfo} buttonText="Verify Twitch Account to Join Waitlist" withEmailButtonText={"Verify & Provide Email"} withoutEmailButtonText="Verify without Email" emailOnly={true} url={props.url}  didUseVote={props.didUseVote} nVotesAvailable={nVotesAvailable} awaitingJoinResponse={awaitingJoinResponse} setAwaitingJoinResponse={setAwaitingJoinResponse} ></LogInToVoteButton>
    } 

    {!selectedWaitlistBatch &&
        <LogInToVoteButton buttonType="purchase" twitchAccessToken={twitchAccessToken} voterInfo={voterInfo} buttonText="Verify Twitch Account to Purchase" withEmailButtonText={"Verify & Provide Email"} withoutEmailButtonText="Verify without Email" emailOnly={true} url={props.url}  didUseVote={props.didUseVote} nVotesAvailable={nVotesAvailable} awaitingJoinResponse={awaitingJoinResponse} setAwaitingJoinResponse={setAwaitingJoinResponse} selectedProductId={selectedProductId} selectedPriceId={selectedPriceId} selectedId={selectedId}></LogInToVoteButton>
    }  
      

      <div className="row justify-content-center mb-5">
        <div className="col-11 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

          <div className="row justify-content-center mt-4">

          <div className="row justify-content-center mt-4">
            <div className="col-auto store-title2-bold">Waitlist</div>
          </div>

          <div className="row justify-content-center mt-4 mb-4">
            <div className="col-auto">
                <div className='row justify-content-center'>Votes will determine the order in which you can purchase a treat bot as they are released.</div>
              </div>
          </div>
                
          </div>

          <LogInToVoteButton buttonType="vote" twitchAccessToken={twitchAccessToken} voterInfo={voterInfo} buttonText="Verify Twitch Account to Vote" withEmailButtonText={"Verify & Provide Email (3 Votes)"} withoutEmailButtonText="Verify without Email (1 Vote)" emailOnly={false} url={props.url}  didUseVote={props.didUseVote}  nVotesAvailable={nVotesAvailable} awaitingJoinResponse={awaitingJoinResponse} setAwaitingJoinResponse={setAwaitingJoinResponse}></LogInToVoteButton>

        <div class="input-group mb-3">
              <input id="streamerSearch" name="streamer" type="text" class="form-control" placeholder="find streamer" aria-label="Streamer's Username" aria-describedby="button-addon2"></input>
              <button class="btn btn-primary" type="button" id="button-addon2" onClick={findStreamer}>Find</button>
        </div>

        {(awaitingJoinResponse || !awaitingJoinResponse || didJoinProductWaitlist || !didJoinProductWaitlist) && (didUseVote || !didUseVote) && foundStreamer && (didSearchStreamer || !didSearchStreamer) &&
        <VoteCountItem didJoinContest={foundStreamer.didJoinContest} voteCount={foundStreamer} voterInfo={voterInfo} accessToken={twitchAccessToken} setNVotesAvailable={setNVotesAvailable} nVotesAvailable={nVotesAvailable} setDidUseVote={setDidUseVote} didSearchStreamer={didSearchStreamer}/>
      }

      {searchWarning && 
        <div className="row justify-content-center">
          <div className="col-6">
            <p>{searchWarning}</p>
          </div>
        </div>
      }

          {/* {(didUseVote || !didUseVote) && streamTurretVoteCounts && !voterInfo && streamTurretVoteCounts.filter((voteCount) => (voteCount.didWinTurret == seeWinners && voteCount.didJoinContest == true)) */}
          {(didJoinProductWaitlist || !didJoinProductWaitlist) && (voterInfo || !voterInfo) && (didUseVote || !didUseVote) && streamTurretVoteCounts && streamTurretVoteCounts.filter((voteCount) => (voteCount.didWinTurret == seeWinners))
          .map((voteCount) => (
            <VoteCountItem didJoinContest={voteCount.didJoinContest} voteCount={voteCount} voterInfo={voterInfo} accessToken={twitchAccessToken} setNVotesAvailable={setNVotesAvailable} nVotesAvailable={nVotesAvailable} setDidUseVote={setDidUseVote} didJoinProductWaitlist={didJoinProductWaitlist}/>
          ))}





        <div className="row">
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <button className="btn btn-primary" disabled={voteCountPreviousToken.length == 0} onClick={() => {
                    setStreamTurretVoteCounts([])
                    setVoteCountNextToken(voteCountSelectedToken)
                    var prevList = voteCountPreviousToken
                    var selectToken = prevList.pop()
                    setVoteCountPreviousToken(prevList)
                    setVoteCountSelectedToken(selectToken)}
                }>
              Prev
            </button>
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" disabled={voteCountNextToken == null} onClick={() => {
                    setStreamTurretVoteCounts([])
                    var prevList = voteCountPreviousToken
                    prevList.push(voteCountSelectedToken)
                    setVoteCountPreviousToken(prevList)
                    setVoteCountNextToken(null)
                    setVoteCountSelectedToken(voteCountNextToken)
                }}>
              Next
            </button>
          </div>
        </div>
        </div>

        </div>
            
      </div>

    </div>
  )

}


function VoteCountItem(props) {
  const displayName = props.voteCount.streamerDisplayName
  const name = props.voteCount.streamerName
  const didWin = props.voteCount.didWinTurret
  const voteLink = "https://www.treatbot.gg/vote?streamer=" + name
  const twitchLink = "https://twitch.tv/" + name


  console.log("voteCount",props.voteCount)

  const [nVotes, setNVotes] = useState(props.voteCount.totalVotes)
  const votingEnded = false

  useEffect(() => {
    setNVotes(props.voteCount.totalVotes)
  }, [props.didSearchStreamer]);


  function onVote() {

    

    if (props.voterInfo) {

      if (props.nVotesAvailable == 0) {
        return
      }

      setNVotes(nVotes + 1)

      props.setNVotesAvailable(props.nVotesAvailable - 1)
      props.setDidUseVote(true)

      return turretVoteVote(props.accessToken, props.voteCount.streamerId)

      .then(res => 
        console.log("didvote",res))


    }
    
  }

  // function copyVoteLink() {
  //   navigator.clipboard.writeText(voteLink)
  // }

  return (
    <div className="row justify-content-center mt-4">


      <div className="col-auto">
        <a className="ms-2" href={twitchLink} target="_blank"><img src="./streamturret-icons/icons8-twitch-100.png" width="24"></img></a>
      </div>


      <div className="col-auto store-body-bold"><div className="row text-start">
        <a className="dark-link" href={voteLink} target="_blank">{displayName}</a></div>
        </div>

      

          <div className="col-auto text-start justify-content-start me-auto">

          {didWin && 
            <div className="row text-start justify-content-left text-green">(has bot!)</div>
          }

        { !props.didJoinContest &&
            <div className="row text-start justify-content-left text-red">(not on waitlist)</div>
          }
          </div>

      <div className="col-4">

        <button className="btn btn-primary" onClick={onVote} disabled={!props.voterInfo || props.voterInfo.votesAvailable == 0 || votingEnded || !props.didJoinContest }>
          <div className="row">

              <div className="col store-body-white">
                {nVotes}
              </div>
              <div className="col d-flex align-items-vertical">
                <img src="./streamturret-icons/icons8-chevron-up-100.png" width="18"></img>
              </div>
          </div>
        </button>
        


      </div>
  </div>
  )



}


function WelcomeMessage(props) {

  console.log("nVotesAvailable",props.nVotesAvailable)

  return (<p className="store-subtitle-bold-purple mb-4">Welcome, {props.voterDisplayName}! You have {props.nVotesAvailable} votes remaining</p>)
}





function BatchCard(props) {

    return (
        <div className={`row ${props.selectedBatchIndex == props.stripeProduct.sortOrder ? 'border border-primary' : 'border'} rounded`}>

            <div className="col">
                <div className="row">
                    <div className="col-6 pt-3">
                        <div class="row text-start">
                            <div className="col">
                                <p className="store-subtitle-bold">{props.stripeProduct.batchName}</p>
                                
                                { !props.stripeProduct.isWaitlist && 
                                    <p className="">{props.stripeProduct.productCount}/{props.stripeProduct.totalProducts}</p>
                                }

                                { props.stripeProduct.isWaitlist && 
                                    <p className="">waitlist</p>
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-6 pt-3">
                        <div class="row text-end">
                            <div className="col">
                                <p className="">${props.stripeProduct.productPrice}</p>
                                <p className="">{props.stripeProduct.shipsString}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


function LogInToVoteButton(props) {

    const [didClickVerify, setDidClickVerify] = useState(false);
    const [buyMessage, setBuyMessage] = useState(null)
    const [paymentUrl, setPaymentURL] = useState(null)

    var redirectURL = props.url

    var twitchURL = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=i48k75a6j36dxliez6ryyxpfzzg4a0&redirect_uri=" + redirectURL + "&force_verify=true"
    var twitchURLWithEmail = "https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=i48k75a6j36dxliez6ryyxpfzzg4a0&redirect_uri=" + redirectURL + "&force_verify=true&scope=user%3Aread%3Aemail"

    function onVerify() {
        setDidClickVerify(!didClickVerify)
    }

    function logOut() {
        window.localStorage.setItem("twitchAccessToken","")
        window.open(props.url,"_self")
    }

    function joinWaitlist() {
        props.setAwaitingJoinResponse(true)
        turretVoteJoinWaitlist(props.twitchAccessToken, props.voterInfo.voterId, !props.voterInfo.didJoinWaitlist)
        .then(res => {
            console.log('res',res)
            props.setAwaitingJoinResponse(false)
        })
    }

    function purchaseProduct() {
      console.log("purchaseBot",props,props.selectedId,props.selectedProductId,props.selectedPriceId)
      turretStoreGetDepositLink(props.twitchAccessToken,props.selectedId,props.selectedProductId,props.selectedPriceId)
			    .then(res => {
			      console.log('purchaseBot',res)

			      if (res.authorized) {
			      	setPaymentURL(res.paymentURL)
              setBuyMessage(res.message)
			      	if (res.paymentURL) {
			      		window.open(res.paymentURL, '_blank');
			      	}
			      } else {
			      	setBuyMessage(res.message)
			      }
			      
			      
			    })
    }

    return (<div>


    

    {!props.voterInfo && 

        <div className="row justify-content-center mb-4">
            <div className="col-auto">
                <div className="row">
                <button className="btn btn-purple" onClick={onVerify} >
                    {props.buttonText}
                </button>
                </div>
            </div>
            </div>

        }


        {props.buttonType == "vote" && 
            <div className='row mb-4 text-blue'>
                <div className="col">
                    +1 vote if verified before TwitchCon
                </div>
            </div>
        }

        {!props.voterInfo && didClickVerify && <div className="row justify-content-center mb-4">
            <div className="col-auto">
                <a href={twitchURLWithEmail} style={{"text-decoration" : "none"}}>
                <div className="row">
                <button className="btn btn-primary" >
                    {props.withEmailButtonText}
                </button>
                </div>
                </a>
            </div>

            {!props.emailOnly &&
                <div className="col-auto ms-1">
                    <a href={twitchURL} style={{"text-decoration" : "none"}}>
                        <div className="row">
                            <button className="btn btn-light-gray" >
                                {props.withoutEmailButtonText}
                            </button>
                        </div>
                    </a>
                </div>
            }

            </div>
        }

        {!props.voterInfo && didClickVerify && <div className="row justify-content-center mb-4">
            <div className="col-12">
                <div className="store-fineprint">By clicking "Verify & Provide Email" you agree to receive email updates from Treat Bot and Type 2 Labs</div>
            </div>
            </div>
        }

        {props.buttonType == "vote" &&  (props.didUseVote || !props.didUseVote) && props.voterInfo && <div className="row justify-content-center">
            <div className="col-auto mb-4">
            <WelcomeMessage nVotesAvailable={props.nVotesAvailable} voterDisplayName={props.voterInfo.voterDisplayName}></WelcomeMessage>
            <button className="btn btn-light-gray" onClick={logOut}>log out</button>
            </div>
        </div>}

        {props.buttonType == "join" && !props.purchaseButton && (props.didUseVote || !props.didUseVote) && props.voterInfo && <div className="row justify-content-center">
            <div className="col-auto mb-4">
            <button disabled={props.awaitingJoinResponse} className="btn btn-primary" onClick={joinWaitlist}>{props.voterInfo.didJoinWaitlist ? "Leave" : "Join"} Waitlist</button>
            </div>
        </div>}

        {props.buttonType == "purchase" && !props.purchaseButton && (props.didUseVote || !props.didUseVote) && props.voterInfo && <div className="row justify-content-center">
            <div className="col-auto mb-4">
            <button className="btn btn-primary" onClick={purchaseProduct}>Purchase</button>
            </div>
        </div>}


        {buyMessage &&
          <div>{buyMessage}</div>
        }

        

        </div>)
}
